import request from '@/utils/request'

// 列表
export function applications_list (params) {
  return request({
    url: '/v1/client/clients',
    method: 'get',
    params
  })
}

// 删除
export function rem_client (id) {
  return request({
    url: '/v1/client/client',
    method: 'delete',
    data: { client_uuid: id }
  })
}

// 删除
export function rem_secret (data) {
  return request({
    url: '/v1/client/client_secret',
    method: 'delete',
    data
  })
}

// 创建
export function add_client (data) {
  return request({
    url: '/v1/client/client',
    method: 'post',
    data: data
  })
}
// 编辑
export function edit_client (data) {
  return request({
    url: '/v1/client/client',
    method: 'put',
    data: data
  })
}

// 创建 secret
export function add_secret (data) {
  return request({
    url: '/v1/client/client_secret',
    method: 'post',
    data: data
  })
}

// 获取应用详情

export function get_clientDetails (params) {
  return request({
    url: '/v1/client/client',
    method: 'get',
    params
  })
}

// 获取应用管理员数据
export function get_client_admin (params) {
  return request({
    url: '/v1/client/client_admin',
    method: 'get',
    params
  })
}

// 保存应用管理员数据
export function post_client_admin (data) {
  return request({
    url: '/v1/client/client_admin',
    method: 'put',
    data: data
  })
}

// 删除应用管理员数据
export function del_client_admin (data) {
  return request({
    url: '/v1/client/client_admin',
    method: 'delete',
    data: data
  })
}

// 获取所有应用
export function all_client_drop_down_menu (params) {
  return request({
    url: '/v1/client/client_drop_down_menu',
    method: 'get',
    params
  })
}

// 列出应用的子应用
export function all_son_client (params) {
  return request({
    url: '/v1/client/all_son_client',
    method: 'get',
    params
  })
}

export function get_client_secret_permissions (params) {
  return request({
    url: '/v1/client/client_secret_permissions',
    method: 'get',
    params
  })
}

// 获取已拉黑的主用户
export function get_blacklist_main_list (params) {
  return request({
    url: '/v1/client/disable_main_user_info',
    method: 'get',
    params
  })
}

// 获取已拉黑的子用户
export function get_blacklist_sub_list (params) {
  return request({
    url: '/v1/client/disable_sub_user_info',
    method: 'get',
    params
  })
}

// 删除已拉黑的用户
export function del_blacklist_list (params) {
  return request({
    url: '/v1/client/disable_users',
    method: 'delete',
    data: params
  })
}

// 获取允许拉黑的用户
export function get_all_blacklist_list (params) {
  return request({
    url: '/v1/client/allow_disable_users',
    method: 'get',
    params
  })
}

// 新增允许拉黑的用户
export function add_blacklist_api (data) {
  return request({
    url: '/v1/client/disable_users',
    method: 'post',
    data
  })
}
