
export function handleClipboard (text, onSuccess, onError) {
  const copy = document.createElement('p')
  copy.innerText = text
  document.getElementsByTagName('body')[0].appendChild(copy)

  const range = document.createRange()
  /* 这里传入的是一个节点，可以是你自己html里面的，不用在这里来创建 如 p标签
        <p class="coyp">我想复制的内容</p> 直接$(".copy")[0] */
  range.selectNode(copy)
  const selection = window.getSelection()
  if (selection) {
    if (selection.rangeCount > 0) selection.removeAllRanges()
    selection.addRange(range)
  }
  document.execCommand('copy')
  /* 这里可以放自己的提示方法*/
  copy.style.display = 'none'
}
