<template>
  <div class="user-add">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span>{{ infoMsg.name }}</span>
    </div>

    <div class="next-card-title fLwpMi">
      应用基本信息
      <div style="margin-left: 16px">
        <button
          type="button"
          class="next-btn next-medium next-btn-primary next-btn-text"
          style="margin-right: 8px"
          @click="editForm"
        >
          <span class="next-btn-helper">编辑基本信息</span>
        </button>
      </div>
    </div>
    <Loading :show="loading">
      <div class="next-row next-row-wrap wind-rc-data-fields">
        <form-label label="名称"> {{ infoMsg.name }} </form-label>
        <form-label label="client_uuid"> {{ infoMsg.client_uuid }} </form-label>
        <form-label label="首页">
          <a :title="infoMsg.home_page" :href="infoMsg.home_page" target="_blank">{{ infoMsg.home_page }}</a>
        </form-label>
        <form-label label="注销url"> {{ infoMsg.revoke_url }}</form-label>
        <form-label label="创建时间"> {{ infoMsg.create_time }} </form-label>
        <form-label label="应用用户变更后的回调url"> {{ infoMsg.user_changed_callback_url }} </form-label>
        <form-label label="权限变动回调接口"> {{ infoMsg.permission_changed_callback_url }} </form-label>
        <form-label label="父级应用"> {{ infoMsg.parent_client_info }} </form-label>
        <form-label label="添加用户回调接口"> {{ infoMsg.add_user_url }} </form-label>
        <form-label label="添加用户回调接口方法"> {{ infoMsg.add_user_method_display }} </form-label>
        <form-label label="删除用户回调接口"> {{ infoMsg.del_user_url }} </form-label>
        <form-label label="删除用户回调接口方法"> {{ infoMsg.del_user_method_display }} </form-label>
        <form-label label="备注"> {{ infoMsg.remark }} </form-label>
      </div>
      <div class="card-table">
        <div class="next-card-title fLwpMi">
          应用基本信息
          <div style="margin-left: 16px">
            <button
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="drawer_secret = true"
            >
              <span class="next-btn-helper">新增客户端密码</span>
            </button>
          </div>
        </div>
        <el-table
          :data="client_secret_info"
          border
          style="width: 100%"

        >
          <el-table-column prop="client_secret_id" label="pid" />
          <el-table-column prop="client_secret_name" label="secret_name" />
          <el-table-column prop="client_secret" label="client_secret" />
          <el-table-column prop="expire" label="过期时间" />
          <el-table-column prop="remark" label="备注" />
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="del_secret(row)"
              >
                <span class="next-btn-helper">删除</span>
              </button>
              &nbsp;
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="lookMyAuthFun(row)"
              >
                <span class="next-btn-helper">管理client_secret权限</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="card-table">
        <div class="next-card-title fLwpMi">
          应用管理员（主用户）
          <div style="margin-left: 16px">
            <button
              v-if="!appAdmin"
              slot="reference"
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="addMainDrawerFun"
            >
              <span class="next-btn-helper">新增应用管理员（主用户）</span>
            </button>

          </div>
        </div>
        <el-table
          :data="mainAppAdminList"
          border
          style="width: 100%"

        >
          <el-table-column prop="name" label="用户名" />
          <el-table-column v-if="!appAdmin" label="删除" width="120">
            <template slot-scope="{ row }">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="del_AppAdmin(row,1)"
              >
                <span class="next-btn-helper">删除</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="card-table">
        <div class="next-card-title fLwpMi">
          应用管理员（子用户）
          <div style="margin-left: 16px">
            <button
              v-if="!appAdmin"
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="addSubDrawerFun"
            >
              <span class="next-btn-helper">新增应用管理员（子用户）</span>
            </button>
          </div>
        </div>
        <el-table
          :data="subAppAdminList"
          border
          style="width: 100%"

        >
          <el-table-column prop="name" label="用户名" />
          <el-table-column prop="domain_name" label="域" />
          <el-table-column v-if="!appAdmin" label="删除" width="120">
            <template slot-scope="{ row }">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="del_AppAdmin(row,2)"
              >
                <span class="next-btn-helper">删除</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="card-table">
        <div class="next-card-title fLwpMi">直接子应用</div>
        <el-table
          v-loading="all_son_loading"
          :data="direct_client_name_list"
          border
          style="width: 100%"

        >
          <el-table-column label="应用名" prop="client_name" />
          <el-table-column label="应用uuid" prop="client_uuid" />
        </el-table>
      </div>

      <div class="card-table">
        <div class="next-card-title fLwpMi">间接子应用</div>
        <el-table
          v-loading="all_son_loading"
          :data="indirect_client_name_list"
          border
          style="width: 100%"

        >
          <el-table-column label="应用名" prop="client_name" />
          <el-table-column label="应用uuid" prop="client_uuid" />
        </el-table>
      </div>

      <div class="card-table">
        <div class="next-card-title fLwpMi">
          应用黑名单（主用户）
          <div style="margin-left: 16px">
            <button
              v-if="$store.state.userInfo.is_client_admin||$store.state.userInfo.is_super"
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="main_disable_d = true"
            >
              <span class="next-btn-helper">新增应用黑名单（主用户）</span>
            </button>
          </div>
        </div>
        <el-table
          v-loading="loading_disable_main"
          :data="disables_main_id_name"
          border
          style="width: 100%"

        >
          <el-table-column prop="user_name" label="用户名" />
          <!-- <el-table-column prop="domain_name" label="域" /> -->
          <el-table-column label="操作" width="120">
            <template slot-scope="{ row }">
              <button
                v-if="$store.state.userInfo.is_client_admin||$store.state.userInfo.is_super"
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="del_blacklist(row,1)"
              >
                <span class="next-btn-helper">删除</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <div style="text-align: right">
            <el-pagination
              :page-size="page.pageSize"
              :current-page="page.pageNum"
              background
              layout="total, prev, pager, next"
              :total="disables_main_id_name_count"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>

      <div class="card-table">
        <div class="next-card-title fLwpMi">
          应用黑名单（子用户）
          <div style="margin-left: 16px">
            <button
              v-if="$store.state.userInfo.is_client_admin||$store.state.userInfo.is_super"
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="sub_disable_d = true"
            >
              <span class="next-btn-helper">新增应用黑名单（子用户）</span>
            </button>
          </div>
        </div>
        <el-table
          v-loading="loading_disable_sub"
          :data="disables_sub_id_name"
          border
          style="width: 100%"

        >
          <el-table-column prop="user_name" label="用户名" />
          <el-table-column prop="domain_name" label="域" />
          <el-table-column label="操作" width="120">
            <template slot-scope="{ row }">
              <button
                v-if="$store.state.userInfo.is_client_admin||$store.state.userInfo.is_super"
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="del_blacklist(row,2)"
              >
                <span class="next-btn-helper">删除</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <div style="text-align: right">
            <el-pagination
              :page-size="page1.pageSize"
              :current-page="page1.pageNum"
              background
              layout="total, prev, pager, next"
              :total="disables_sub_id_name_count"
              @current-change="handleCurrentChange1"
            />
          </div>
        </div>
      </div>

    </Loading>

    <el-drawer
      ref="drawer"
      custom-class="drawer-warp"
      title="更新应用"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="首页" prop="home_page">
            <el-input v-model="form.home_page" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="父级应用名称">
            <el-select
              v-model="form.parent_client_uuid"
              placeholder="请选择"
              style="width:100%"
              clearable
            >
              <el-option
                v-for="item in parentList"
                :key="item.client_uuid"
                :label="item.name"
                :value="item.client_uuid" />
            </el-select>
          </el-form-item>
          <el-form-item label="注销" prop="revoke_url">
            <el-input v-model="form.revoke_url" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="应用用户变更后的回调url" prop="user_changed_callback_url">
            <el-input v-model="form.user_changed_callback_url" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="权限变动回调接口" prop="permission_changed_callback_url">
            <el-input v-model="form.permission_changed_callback_url" placeholder="请输入" />
          </el-form-item>

          <el-form-item label="添加用户回调接口" prop="add_user_url">
            <el-input v-model="form.add_user_url" placeholder="请输入地址" class="input-with-select" clearable>
              <el-select
                slot="prepend"
                v-model="form.add_user_method"
                placeholder="请选择方法"
                style="width:125px"
                clearable
              >
                <el-option label="POST" :value="1" />
                <el-option label="DELETE" :value="2" />
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item label="删除用户回调接口" prop="del_user_url">
            <el-input v-model="form.del_user_url" placeholder="请输入地址" class="input-with-select" clearable>
              <el-select
                slot="prepend"
                v-model="form.del_user_method"
                placeholder="请选择方法"
                style="width:125px"
                clearable
              >
                <el-option label="POST" :value="1" />
                <el-option label="DELETE" :value="2" />
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" :autosize="{ minRows: 4, maxRows: 4}" type="textarea" placeholder="请输入" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submit('ruleForm')"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="closeDrawer('ruleForm')"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <!-- 新增 client_secret -->
    <el-drawer
      custom-class="drawer-warp"
      title="添加客户端密码"
      :visible.sync="drawer_secret"
    >
      <div class="drawer__content">
        <el-form
          ref="secret"
          :model="form_secret"
          class="demo-ruleForm"
          label-position="top"
        >

          <el-form-item label="secret_name" prop="secret_name" class="is-required">
            <el-input v-model="form_secret.secret_name" placeholder="请输入" />
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="form_secret.remark" :autosize="{ minRows: 4, maxRows: 4}" type="textarea" placeholder="请输入" />
          </el-form-item>
          <!-- <el-form-item label="截止期限" prop="remark">
            <el-date-picker
              v-model="form_secret.value2"
              type="month"
              placeholder="选择月" />
          </el-form-item> -->
          <el-form-item label="截止日期" prop="expire" class="is-required">
            <el-date-picker
              v-model="form_secret.expire"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="submitSecret('secret')"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="drawer_secret = false"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <!-- 添加主应用管理员 -->
    <el-drawer
      custom-class="drawer-warp"
      title="新增应用管理员（主用户）"
      :visible.sync="addMainDrawer"
    >
      <div class="drawer__content">
        <p style="margin:10px 0">请勾选你需要添加的数据</p>
        <el-table
          ref="multipleTableSub"
          :data="not_client_admin_main_users"
          border
          style="width: 100%"
          @selection-change="handleSelectionChangeMain"

        >
          <el-table-column
            type="selection"
            width="55" />
          <el-table-column prop="name" label="用户名" />
        </el-table>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="add_app_admin(1)"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="addMainDrawer = false"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <!-- 添加子应用管理员 -->
    <el-drawer
      custom-class="drawer-warp"
      title="新增应用管理员（子用户）"
      :visible.sync="addSubDrawer"
    >
      <div class="drawer__content">
        <p style="margin:10px 0">请勾选你需要添加的数据</p>
        <el-table
          ref="multipleTableSub"
          :data="not_client_admin_sub_users"
          border
          style="width: 100%"
          @selection-change="handleSelectionChangeSub"

        >
          <el-table-column
            type="selection"
            width="55" />
          <el-table-column prop="name" label="用户名" />
          <el-table-column prop="domain_name" label="域" />
        </el-table>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="add_app_admin(2)"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="addSubDrawer = false"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <Drawer
      v-model="drawer_client_secret_permissions"
      title="我拥有的权限"
      draggable
      width="600px"
    >
      <div class="drawer__content">
        <el-table
          v-loading="client_secret_permissions_loading"
          empty-text="当前暂无权限"
          :data="client_secret_permissions_list"
          border
          style="width: 100%">
          <el-table-column prop="name" label="权限名" />
          <el-table-column label="操作">
            <template slot-scope="{row}">
              <!-- v-if="$store.state.userInfo.is_client_admin||$store.state.userInfo.is_super" -->
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="del_permissions_drawer(row)"
              >
                <span class="next-btn-helper">删除</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
        <button
          type="button"
          class="next-btn next-medium next-btn-primary next-btn-text"
          style="margin:10px 5px"
          @click="add_permissions_select_drawer = true"
        >
          <span class="next-btn-helper">新增权限</span>
        </button>

      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="drawer_client_secret_permissions = false"
          >
            <span class="next-btn-helper">关闭</span>
          </button>
        </div>
      </div>
    </Drawer>

    <el-drawer
      custom-class="drawer-warp"
      title="新增应用黑名单（主用户）"
      :visible.sync="main_disable_d"
    >
      <div class="drawer__content">
        <p style="margin:10px 0">请勾选你需要添加的数据</p>
        <el-table
          ref="multipleTableSub"
          :data="allow_disable_main_id_name"
          border
          style="width: 100%"
          @selection-change="handleSelectionChangeDisableMain"

        >
          <el-table-column
            type="selection"
            width="55" />
          <el-table-column prop="user_name" label="用户名" />
          <!-- <el-table-column prop="domain_name" label="域" /> -->
        </el-table>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="add_blacklist(1)"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="main_disable_d = false"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      custom-class="drawer-warp"
      title="新增应用黑名单（子用户）"
      :visible.sync="sub_disable_d"
    >
      <div class="drawer__content">
        <p style="margin:10px 0">请勾选你需要添加的数据</p>
        <el-table
          ref="multipleTableSub"
          :data="allow_disable_sub_id_name"
          border
          style="width: 100%"
          @selection-change="handleSelectionChangeDisableSub"

        >
          <el-table-column
            type="selection"
            width="55" />
          <el-table-column prop="user_name" label="用户名" />
          <el-table-column prop="domain_name" label="域" />
        </el-table>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="add_blacklist(2)"
          >
            <span class="next-btn-helper">确定</span>
          </button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="sub_disable_d = false"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      title="新增权限"
      :visible.sync="add_permissions_select_drawer"
      width="500px">
      <div>
        <label for="">请选择权限: &nbsp;</label>
        <el-select v-model="client_secret_value" style="width:300px" filterable multiple placeholder="每次只能选择同域权限" @change="changeDisabled">
          <el-option
            v-for="item in client_secret_not_permissions"
            :key="item.id"
            :disabled="item.disabled"
            :label="item.name+'@'+item.domain__name"
            :value="item.id" />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_permissions_select_drawer = false">取 消</el-button>
        <el-button type="primary" @click="add_permissions_select_drawer_submit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  get_clientDetails,
  edit_client,
  add_secret,
  rem_secret,
  get_client_admin,
  post_client_admin,
  del_client_admin,
  all_client_drop_down_menu,
  all_son_client,
  get_client_secret_permissions,
  get_blacklist_main_list,
  get_blacklist_sub_list,
  del_blacklist_list,
  get_all_blacklist_list,
  add_blacklist_api
} from '@/api/applications'

import { add_permission, del_permission } from '@/api'

import { isExternal } from '@/utils/validate'
import { handleClipboard } from '@/utils/clipboard'

export default {
  data () {
    const checkExternal = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入首页地址'))
      }
      if (isExternal(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的首页地址'))
      }
    }
    const noCheckExternal = (rule, value, callback) => {
      if (value) {
        if (isExternal(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的地址'))
        }
      } else {
        callback()
      }
    }

    return {
      loading: false,
      drawer: false,
      addMainDrawer: false,
      addSubDrawer: false,
      all_son_loading: false,
      drawer_secret: false,
      main_disable_d: false,
      sub_disable_d: false,
      loading_disable_main: false,
      loading_disable_sub: false,
      drawer_client_secret_permissions: false,
      add_permissions_select_drawer: false,
      client_secret_permissions_loading: false,
      client_secret_value: [],
      tableData: [],
      infoMsg: {},
      form: {},
      client_secret_not_permissions: [],
      client_secret_info: [],
      form_secret: {},
      mainAppAdminList: [],
      subAppAdminList: [],
      allMainAppAdminList: [],
      allSubAppAdminList: [],
      main_id_list: [],
      sub_id_list: [],
      parentList: [],
      not_client_admin_main_users: [],
      not_client_admin_sub_users: [],
      client_secret_permissions_list: [],
      direct_client_name_list: [],
      indirect_client_name_list: [],
      disables_main_id_name: [],
      disables_sub_id_name: [],
      allow_disable_main_id_name: [],
      allow_disable_sub_id_name: [],
      main_disable_id_list: [],
      sub_disable_id_list: [],
      lookMyAuthFunItem: {},
      disables_main_id_name_count: 0,
      disables_sub_id_name_count: 0,
      page: { pageNum: 1, pageSize: 5 },
      page1: { pageNum: 1, pageSize: 5 },
      rules_secret: {
        home_page: [{ validator: checkExternal, required: true, trigger: 'blur' }]
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        home_page: [{ validator: checkExternal, required: true, trigger: 'blur' }],
        add_user_url: [{ validator: noCheckExternal, trigger: ['blur', 'change'] }],
        del_user_url: [{ validator: noCheckExternal, trigger: ['blur', 'change'] }]
      }
    }
  },
  computed: {
    // 是否应用管理员
    appAdmin () {
      return !this.$store.state.userInfo.is_super
    }
  },
  mounted () {
    this.get_info()
    this.get_app_list()
    this.get_parent_list()
    this.get_all_son_client()
    // 应用黑名单
    // this.get_blacklist()
    this.get_disable_main_user_info()
    this.get_disable_sub_user_info()
    this.all_blacklist_list()
  },
  methods: {
    changeDisabled () {
      const eid = this.client_secret_value[0]
      console.log(eid)

      this.client_secret_not_permissions.forEach(item => {
        if (item.id == eid) {
          this.client_secret_not_permissions.forEach(z => {
            if (z.domain_id != item.domain_id) {
              z.disabled = true
            } else {
              z.disabled = false
            }
          })
        }

        if (!this.client_secret_value.length) {
          item.disabled = false
        }
      })
    },

    del_permissions_drawer ({ grant_id }) {
      const data = {
        'client_id': 1, // 应用id
        'grant_id': grant_id // 授权信息id
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del_permission(data).then(res => {
          if (res.code === 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.lookMyAuthFun(this.lookMyAuthFunItem)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    add_permissions_select_drawer_submit () {
      const data = {
        client_id: this.infoMsg.id,
        be_added_type: 1,
        add_to_type: 5,
        be_added_id: this.client_secret_value,
        add_to_id: [this.lookMyAuthFunItem.client_secret_id],
        user_type: this.client_secret_value.map(() => 2)
      }
      add_permission(data).then(res => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
        }
        this.lookMyAuthFun(this.lookMyAuthFunItem)
        this.add_permissions_select_drawer = false
        this.client_secret_value = []
      })
    },

    handleCurrentChange (val) {
      this.page.pageNum = val
      this.get_disable_main_user_info()
    },
    handleCurrentChange1 (val) {
      this.page1.pageNum = val
      this.get_disable_sub_user_info()
    },

    get_disable_main_user_info () {
      const params = {
        client_uuid: this.$route.query.client_uuid,
        ...this.page
      }
      this.loading_disable_main = true
      get_blacklist_main_list(params).then(res => {
        this.loading_disable_main = false
        this.disables_main_id_name = res.data.disables_main_id_name
        this.disables_main_id_name_count = res.disables_main_id_name_count
      }).catch(() => {
        this.loading_disable_main = false
      })
    },

    get_disable_sub_user_info () {
      const params = {
        client_uuid: this.$route.query.client_uuid,
        ...this.page1
      }
      this.loading_disable_sub = true
      get_blacklist_sub_list(params).then(res => {
        this.disables_sub_id_name = res.data.disables_sub_id_name
        this.disables_sub_id_name_count = res.disables_sub_id_name_count
        this.loading_disable_sub = false
      }).catch(() => {
        this.loading_disable_sub = false
      })
    },

    add_blacklist (type) {
      const data = {
        client_uuid: this.$route.query.client_uuid,
        main_user_list: [],
        sub_user_list: []
      }

      if (type == 1) {
        data.main_user_list = this.main_disable_id_list
      } else {
        data.sub_user_list = this.sub_disable_id_list
      }

      add_blacklist_api(data).then(res => {
        if (res.code == 0) {
          if (type == 1) {
            this.get_disable_main_user_info()
          } else {
            this.get_disable_sub_user_info()
          }
          this.all_blacklist_list()
          this.main_disable_d = false
          this.sub_disable_d = false
        }
      })
    },

    handleSelectionChangeDisableMain (list) {
      this.main_disable_id_list = list.map(({ user_id }) => user_id)
    },
    handleSelectionChangeDisableSub (list) {
      this.sub_disable_id_list = list.map(({ user_id }) => user_id)
    },
    all_blacklist_list () {
      get_all_blacklist_list({ client_uuid: this.$route.query.client_uuid }).then(res => {
        this.allow_disable_main_id_name = res.data.allow_disable_main_id_name
        this.allow_disable_sub_id_name = res.data.allow_disable_sub_id_name
      })
    },

    del_blacklist (row, type) {
      const params = {
        'client_uuid': this.$route.query.client_uuid
      }

      if (type == 1) {
        params.del_main_user_id = row.user_id
      } else {
        params.del_sub_user_id = row.user_id
      }

      this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del_blacklist_list(params).then(() => {
          if (type == 1) {
            this.page.pageNum = 1
            this.get_disable_main_user_info()
          } else {
            this.page1.pageNum = 1
            this.get_disable_sub_user_info()
          }
          this.all_blacklist_list()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    lookMyAuthFun (row) {
      this.lookMyAuthFunItem = row
      this.client_secret_permissions_loading = true
      this.drawer_client_secret_permissions = true

      get_client_secret_permissions({ client_secret_id: row.client_secret_id }).then(res => {
        this.client_secret_permissions_loading = false
        this.client_secret_permissions_list = res.data.client_secret_permissions
        this.client_secret_not_permissions = res.data.client_secret_not_permissions
        this.client_secret_not_permissions.forEach(item => {
          item.disabled = false
        })
      }).catch(() => {
        this.client_secret_permissions_loading = false
      })
    },

    get_all_son_client () {
      this.all_son_loading = true
      all_son_client({ client_uuid: this.$route.query.client_uuid })
        .then(res => {
          this.all_son_loading = false
          this.direct_client_name_list = res.data.direct_client_name_list
          this.indirect_client_name_list = res.data.indirect_client_name_list
        })
    },
    get_parent_list () {
      all_client_drop_down_menu().then(res => {
        this.parentList = (res.data || [])
      })
    },
    del_AppAdmin ({ id }, type) {
      const data = {
        update_user_type: type,
        client_admin_id: id,
        client_uuid: this.$route.query.client_uuid
      }

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del_client_admin(data).then(res => {
          this.get_app_list()
          if (!res.code) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 保存管理员
    add_app_admin (type) {
      const data = {
        update_user_type: type,
        client_uuid: this.$route.query.client_uuid,
        client_admin_main_users: this.main_id_list, // 主用户id
        client_admin_sub_users: this.sub_id_list // 子用户id
      }
      post_client_admin(data).then(res => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
        }
        this.get_app_list()
        this.addSubDrawer = false
        this.addMainDrawer = false
      })
    },

    // 获取主用户当前列表所选id
    handleSelectionChangeMain (list) {
      this.main_id_list = list.map(({ id }) => id)
    },
    // 获取子用户当前列表所选id
    handleSelectionChangeSub (list) {
      this.sub_id_list = list.map(({ id }) => id)
    },

    // 打开子用户抽屉
    addSubDrawerFun () {
      this.addSubDrawer = true
      this.$nextTick(() => {
        const Alist = this.subAppAdminList.map(({ user_id }) => user_id)
        const list = this.allSubAppAdminList.filter(item => Alist.includes(item.id))

        list.forEach(row => this.$refs.multipleTableSub.toggleRowSelection(row))
      })
    },
    // 打开主用户抽屉
    addMainDrawerFun () {
      this.addMainDrawer = true
      this.$nextTick(() => {
        const Alist = this.mainAppAdminList.map(({ user_id }) => user_id)
        const list = this.allMainAppAdminList.filter(item => Alist.includes(item.id))

        list.forEach(row => this.$refs.multipleTableMain.toggleRowSelection(row))
      })
    },

    get_app_list () {
      this.loading = true
      get_client_admin({ client_uuid: this.$route.query.client_uuid })
        .then(res => {
          this.loading = false
          this.mainAppAdminList = res.data.main_users
          this.subAppAdminList = res.data.sub_users
          this.allMainAppAdminList = res.data.all_main_users
          this.allSubAppAdminList = res.data.all_sub_users
          this.not_client_admin_main_users = res.data.not_client_admin_main_users
          this.not_client_admin_sub_users = res.data.not_client_admin_sub_users
        })
    },
    del_secret (row) {
      const params = {
        client_uuid: this.$route.query.client_uuid,
        client_secret_id: row.client_secret_id
      }

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rem_secret(params).then(res => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.get_info()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    get_info () {
      this.loading = true
      const params = {
        client_uuid: this.$route.query.client_uuid
      }
      get_clientDetails(params).then(res => {
        this.infoMsg = res.data
        this.client_secret_info = res.secret_data
        this.loading = false
      })
    },

    editForm () {
      this.drawer = true
      this.form = JSON.parse(JSON.stringify(this.infoMsg))
    },

    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const postData = {
            name: this.form.name,
            home_page: this.form.home_page,
            revoke_url: this.form.revoke_url,
            user_changed_callback_url: this.form.user_changed_callback_url,
            remark: this.form.remark,
            client_uuid: this.form.client_uuid,
            permission_changed_callback_url: this.form.permission_changed_callback_url,
            parent_client_uuid: this.form.parent_client_uuid,
            add_user_url: this.form.add_user_url,
            del_user_url: this.form.del_user_url,
            add_user_method: this.form.add_user_method,
            del_user_method: this.form.del_user_method
          }
          edit_client(postData).then(res => {
            if (res.code == 0) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
            }
            this.drawer = false
            this.get_info()
            this.get_parent_list()
            this.$refs[formName].resetFields()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    submitSecret (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const postData = { ...this.form_secret, client_uuid: this.$route.query.client_uuid }
          add_secret(postData).then(res => {
            if (res.code == 0) {
              const h = this.$createElement
              this.$msgbox({
                title: '提示',
                message: h('p', null, [
                  h('p', { style: 'font-size: 16px;margin-bottom:5px;' }, '新增成功，密码为： '),
                  h('i', { style: 'color: #409eff' }, res.data),
                  h('i', {
                    class: 'el-icon-copy-document',
                    style: 'margin-left:5px;cursor: pointer;',
                    on: { click: () => {
                      handleClipboard(res.data)
                      this.$message({
                        message: '已复制',
                        type: 'success'
                      })
                    } }
                  }),
                  h('p', { style: 'color: #D9001B;margin-top:5px;' }, '请及时保存，之后无法看到明文!!!')
                ]),
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                  done()
                }
              })
            }
            this.drawer_secret = false
            this.get_info()
            this.$refs[formName].resetFields()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    closeDrawer (formName) {
      this.$refs[formName].resetFields()
      this.drawer = false
    },

    previous () {
      this.$router.push('/applications')
    }
  }
}
</script>

<style lang="less" scoped>
 .fLwpMi {
    margin-bottom: 12px;
    white-space: nowrap;
  }
  .fLwpMi > h3 {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
  }
  h3,
  h4 {
    margin-bottom: 7px !important;
    font-size: 14px;
  }
  .fLwpMi div:first-of-type {
    display: inline-block;
    margin-left: 40px;
  }
  .cGFAXW {
    margin-bottom: 20px;
  }
  .fLwpMi {
    margin-bottom: 12px;
    white-space: nowrap;
  }
  .eEgMuO {
    display: flex;
    margin-bottom: 8px;
    width: 50%;
    min-width: 300px;
  }
  .TJhJs {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .eRLVLq {
    margin-right: 16px;
    width: 164px;
  }
</style>
